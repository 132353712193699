import React from "react";
import gceLogo from "../../assets/gce-gascontrol.svg";

export default function Footer() {
  return (
    <div className="footer-section">
      <img src={gceLogo} className="gce-footer-logo" alt="gce" />
      <hr className="footer-line" />
      <p>
        <a
          href="https://www.gcegroup.com/en/legal-privacy-notice"
          className="white text-small privacy-policy-link"
        >
          Legal & Privacy Notice{" "}
        </a>
        <a
          href="https://www.gcegroup.com/"
          className="white text-small privacy-policy-link"
        >
          | www.gcegroup.com
        </a>
      </p>
    </div>
  );
}
