import React from "react";
import safetyImg from "../../assets/GCE_safety-min.jpg";
import durabilityImg from "../../assets/GCE_durability-min.jpg";
import accuracyImg from "../../assets/GCE_accuracy-min.jpg";

export default function Features(props) {
  // const title = props.pageData.expectations_title[0].text.split(' ').splice(0,4)
  const title = props.pageData
    .getValueOf(props.pageData, "expectations_title[0].text")
    .split(" ")
    .splice(0, 4);

  const remainingTitle = props.pageData
    .getValueOf(props.pageData, "expectations_title[0].text")
    .split(" ")
    .splice(4)
    .join(" ");

  return (
    <div className="features">
      <p className="center uppercase red features-title">
        <strong>{title[0]}</strong> {title[1]} {title[2]}{" "}
        <strong> {title[3]}</strong> {remainingTitle}
      </p>
      <div className="features-container">
        <div className="safety feature-div">
          <img alt="safety" src={safetyImg} />
          <h5 className="white center uppercase">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_one_name[0].text"
            )}
          </h5>
          <p className="white center text-medium ">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_one_text[0].text"
            )}
          </p>
        </div>

        <div className="durability feature-div">
          <img alt="durability" src={durabilityImg} />
          <h5 className="white center uppercase">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_two_name[0].text"
            )}
          </h5>
          <p className="white center text-medium ">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_two_text[0].text"
            )}
          </p>
        </div>

        <div className="accuracy feature-div">
          <img alt="accuracy" src={accuracyImg} />
          <h5 className="white center uppercase">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_three_name[0].text"
            )}
          </h5>
          <p className="white center text-medium">
            {props.pageData.getValueOf(
              props.pageData,
              "expectation_three_text[0].text"
            )}
          </p>
        </div>
      </div>
      <div className="bottom-container" />
    </div>
  );
}
