const figureOutRegionAndLanguage = (regionParam, languageParam) => {   
     let language;
     let country;
     let explicitLang
    switch (regionParam) {
        case "united_kingdom":
          language = "en-gb";
          country = 'United Kingdom';         ;
          break;
        case "germany":
          language = "de-de";
          country = 'Germany';
          break;
        case "czech_republic":
          language = "cs-cz";
          country = 'Czech Republic';
          break;
        case "hungary":
          language = "hu";
          country = 'Hungary';
          break;
        case "russia":
          language = "ru";
          country = 'Russia';
          break;
        case "france":
          language = "fr-fr";
          country = 'France';
          break;
        case "romania":
          language = "ro";
          country = 'Romania';
          break;
        case "italy":
          language = "it-it";
          country = 'Italy';
          break;
        case "spain":
          language = "es-es";
          country = 'Spain';
          break;
        case "portugal":
          language = "pt-pt";
          country = 'Portugal';
          break;
        //Sweden
        case "sweden":
          language = "sv-se";
          country = 'Sweden';
          break;
        //India
        case "india":
          language = "en-in";
          country = 'India';
          break;
        case "china":
          language = "zh-cn";
          country = 'China';
          break;
        case "poland":
          language = "pl";
          country = 'Poland';
          break;
        case "latin_america" :
          language = "en-la"; 
          country = 'Latin America';
          break;      
        default:
          language = null;
          country = null
      }

      switch (languageParam) {
        case "en":
          explicitLang = 'English' ;
          break;
        case "de":
            explicitLang = 'German' ;
          break;
        case "cz":
            explicitLang = 'Czech' ;
          break;
        case "hu":
            explicitLang = 'Hungarian' ;;
          break;
        case "ru":
            explicitLang = 'Russian' ;
          break;
        case "fr":
            explicitLang = 'French' ;
          break;
        case "ro":
            explicitLang = 'Romanian' ;
          break;
        case "it":
            explicitLang = 'Italian' ;
          break;
        case "es":
            explicitLang = 'Spanish' ;
          break;
        case "pt":
            explicitLang = 'Portuguese' ;
          break;       
        case "zh":
            explicitLang = 'Chinese' ;
          break;
        case "pl":
            explicitLang = 'Polish' ;
          break;        
        default:
            explicitLang = null ;
      }  
     
      return {language, country, explicitLang};
      
}
export {figureOutRegionAndLanguage };

    // let languageIndex;
      // let currentData = this.state.cmsData.filter(version => {
      //   return version.data.region_name_in_english[0].text === results.country;
      // });
      // currentData[0].data.available_languages_for_region.forEach(
      //   (language, index) => {
      //     if (language.text === results.explicitLang) {
      //       languageIndex = index;
      //     }
      //   }
      // );
      //console.log(languageIndex)