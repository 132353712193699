import React from "react";
import Hero from "./Hero";
import Features from "./Features";
import Supplier from "./Supplier";
import ProductInformation from "./ProductInformation";
import Footer from "./Footer";
import { Modal } from "react-bootstrap";
import ModalComponent from "../Modal/Modal";

export default function Main(props) {
  return (
    <div className="main">
      <Hero
        manualLanguageChange={props.manualLanguageChange} 
        noNativeLanguage={props.noNativeLanguage}
        defaultMode={props.defaultMode}
        currentCountry={props.currentCountry}
        currentLanguage={props.currentLanguage}
        updatePreferences={props.updatePreferences}
        pageData={props.pageData.data}
        showModal={props.showModal}
        fullState={props.fullState}
        country={props.country}
      />
      <Supplier
        queryParams={props.queryParams}
        supplierInfo={props.supplierInfo}
        changedLanguage={props.changedLanguage}
        englishVersionData={props.englishVersionData}
        defaultMode={props.defaultMode}
        languageCode={props.pageData.lang}
        pageData={props.pageData.data}
        country={props.country}
      />
      <ProductInformation
        moreInfoLink={props.moreInfoLink}
        pageData={props.pageData.data}
      />
      <Features pageData={props.pageData.data} />
      
      <Footer />
      <Modal show={props.modalOpen} onHide={() => props.closeModal()}>
        <ModalComponent
          noNativeLanguage={props.noNativeLanguage}
          currentLanguage={props.currentLanguage}
          changeDefaultMode={props.changeDefaultMode}
          updateRegion={props.updateRegion}
          fullState={props.fullState}
          country={props.country}
          pageData={props.pageData}
          closeModal={props.closeModal}
          updatePreferences={props.updatePreferences}
        />
      </Modal>
    </div>
  );
}
