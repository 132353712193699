import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Component } from 'react';
import './App.css';
import './Responsive.css';
import Prismic from 'prismic-javascript';
import './Responsive.css';
import Main from '../Main/Main';
import loadGoogleMapsApi from 'load-google-maps-api';
import { figureOutRegionAndLanguage } from '../../utils/helpers';

class App extends Component {
  state = {
    cmsData: null,
    country: '',
    agreedGeolocation: false,
    hasGeolocation: true,
    language: 'en-gb',
    moreInfoLink: '',
    modalOpen: false,
    defaultMode: true,
    currentCountry: '',
    currentLanguage: '',
    changedLanguage: false,
    supplierInfo: {},
    noNativeLanguage: false,
    userLanguageChange: false,
    isChromeBrowser: '',
    queryParams: false
  };

  componentDidMount() {
    //fetch data then ask for location
    this.fetchCMSData(this.queryParams);
  }

  queryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const region = urlParams.get('region');
    const language = urlParams.get('lang');

    //check for the query params
    if (region) {
      const results = figureOutRegionAndLanguage(region, language);
      //console.log('results',results)
      // if the region and language are both explicitly set, update preferences
      // so we still have a memory,
      if (results.country && results.explicitLang) {
        this.setState(
          {
            country: results.country,
            queryParams: true,
            defaultMode: false,
            currentLanguage: results.explicitLang
          },
          () =>
            this.updatePreferences({
              lang: results.explicitLang,
              country: results.country
            })
        );
      } else if (results.country) {
        this.setState(
          {
            queryParams: true,
            language: results.language,
            country: results.country,
            defaultMode: false
          },
          () => this.askForGeolocation
        );
      } else {
        //defaults to geolocation
        this.askForGeolocation();
      }
    } else {
      //if there is no region query parameters then use geolocation and the app behaves normal
      this.askForGeolocation();
    }
  };

  fetchCMSData = callbackOne => {
    const apiEndpoint = 'https://gce-procontrol.prismic.io/api/v2';
    Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'page'), { lang: '*' })
        .then(response => {
          // Move  uk - english content in the first place
          let data = response.results;
          let ukversion = data.filter(item => item.uid === 'uk-version');
          response.results = data.filter(item => item.uid !== 'uk-version');
          response.results.unshift(ukversion[0]);
          if (response) {
            this.setState({ cmsData: response.results }, callbackOne);
          }
        });
    });
  };

  askForGeolocation = () => {
    //Check for browser
    let isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    //console.log(this.state.queryParams)
    //if the browser is NOT chrome, and there are NO query parameters
    if (!isChrome && !this.state.queryParams) {
      setTimeout(() => {
        if (
          this.state.agreedGeolocation === false &&
          this.state.userLanguageChange === false
        ) {
          //forcefully triggering lookup
          //console.log('forcefully using IPlookup')
          this.getCountryWithIP();
        }
      }, 5000);
      // })
    }
    if (!this.state.queryParams) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getCoords, error => {
          this.setState({ agreedGeolocation: false });
          this.getCountryWithIP();
        });
      } else {
        this.setState({ hasGeolocation: false });
        this.getCountryWithIP();
      }
    }
  };

  getCountryWithIP = function() {
    //console.log('🎉 IP based GeoLocation')
    fetch(`https://ipapi.co/json/`)
      .then(res => res.json())
      .then(locationInfo => {
        let countryName;
        locationInfo.country_name === 'Czechia'
          ? (countryName = 'Czech Republic')
          : (countryName = locationInfo.country_name);

        let language = this.state.cmsData.filter(version => {
          return version.data.region_name_in_english[0].text === countryName;
        });
        this.setState({ currentCountry: countryName });
        /* We have to check if  result of the ip API is in our CMS system.. 
             if not we have to fall back to default
          */
        if (language.length === 0) {
          this.setState({ defaultMode: true });
        } else {
          this.setState(
            { country: countryName, language: language[0].lang },
            this.isCountryAvailableInApi
          );
        }
      })
      .catch(error => {
        console.error('Find ipapi.co returned with error', error);
        this.setState({ defaultMode: true });
      });
  };

  getCoords = position => {
    const longitude = position.coords.longitude;
    const latitude = position.coords.latitude;
    this.setState({
      latitude: latitude,
      longitude: longitude,
      agreedGeolocation: true
    });
    this.getCountry(longitude, latitude);
  };

  getCountry = (longitude, latitude) => {
    const GOOGLE_MAP_KEY = 'AIzaSyCLv3BaG6JvXzdeEtfsZeGTpWX72wuoGpI';
    var that = this;
    const latinAmericaCountries = [
      'Brazil',
      'Mexico',
      'Colombia',
      'Argentina',
      'Peru',
      'Venezuela',
      'Chile',
      'Guatemala',
      'Ecuador',
      'Cuba',
      'Bolivia',
      'Haiti',
      'Dominican Republic',
      'Honduras',
      'Paraguay',
      'El Salvador',
      'Nicaragua',
      'Costa Rica',
      'Panama',
      'Uruguay',
      'Jamaica',
      'Trinidad and Tobago',
      'Guyana',
      'Suriname',
      'Bahamas',
      'Belize',
      'Barbados',
      'Saint Lucia',
      'St. Vincent & Grenadines',
      'Grenada',
      'Antigua and Barbuda',
      'Dominica',
      'Saint Kitts & Nevis'
    ];

    const checkLanguage = country => {
      let language = that.state.cmsData.filter(version => {
        return version.data.region_name_in_english[0].text === country;
      });
      if (country === 'Czechia') {
        country = 'Czech Republic';
        //console.log("czechia");
      }
      that.setState({ currentCountry: country });
      if (language.length === 0) {
        that.setState(
          { country: country, defaultMode: true },
          that.isCountryAvailableInApi
        );
      } else if (language.length === 1) {
        that.setState(
          { country: country, language: language[0].lang, defaultMode: false },
          that.isCountryAvailableInApi
        );
      }
    };

    loadGoogleMapsApi({ key: GOOGLE_MAP_KEY, language: 'en' })
      .then(function(googleMaps) {
        var geocoder = new googleMaps.Geocoder();
        const geocodeLatLng = geocoder => {
          geocoder.geocode(
            { location: { lat: latitude, lng: longitude } },
            function(results, status) {
              if (status === 'OK') {
                if (results[0]) {
                  let country = results[results.length - 1].formatted_address;
                  if (latinAmericaCountries.includes(country)) {
                    country = 'Latin America';
                  }
                  if (country === 'Czechia') {
                    country = 'Czech Republic';
                  }
                  checkLanguage(country);
                } else {
                  //console.log(this, "No results found");
                  that.setState(
                    { defaultMode: true },
                    that.isCountryAvailableInApi
                  );
                }
              } else {
                console.log('Geocoder failed due to: ' + status);
                that.setState(
                  { defaultMode: true },
                  that.isCountryAvailableInApi
                );
              }
            }
          );
        };
        //start the geocoding process
        geocodeLatLng(geocoder);
      })
      .catch(function(error) {
        console.error(error);
        this.setState({ defaultMode: true }, this.isCountryAvailableInApi);
      });

    //Geolocation API query string version
    //  fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&key=' + GOOGLE_MAP_KEY)
    //   .then((res) => res.json())
    //   .then(json => {
    //      console.log(json)
    //   })
    //   .catch( (err)=> console.log(err))

    // openStreetMap version
    // var that  = this
    //  const URL = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=2`;
    //  fetch(URL)
    //      .then(res => res.json())
    //      .then(data => {
    //        let language = this.state.cmsData.filter((version) => {
    //          return version.data.region_name_in_english[0].text === country;
    //        })
    //        this.setState({currentCountry: country  })
    //        if (language.length === 0) {
    //          this.setState({ country: country, defaultMode:true } ,  this.isCountryAvailableInApi)
    //        }else if(language.length === 1) {
    //         this.setState({ country: country, language: language[0].lang , defaultMode:false } ,  this.isCountryAvailableInApi)
    //        }

    //      })
    //      .catch( (err) => {
    //        console.error(err)
    //        that.setState({ defaultMode:true } ,  that.isCountryAvailableInApi)
    //      })
  };

  isCountryAvailableInApi = () => {
    let isCountryFoundInCMS = false;
    var that = this;

    isCountryFoundInCMS = this.state.cmsData.find(function(cmsData) {
      return that.state.country === cmsData.data.region_name_in_english[0].text;
    });

    if (isCountryFoundInCMS) {
      this.setState({ defaultMode: false });
    } else {
      this.setState({ defaultMode: true });
    }
  };

  updatePreferences = lang => {
    //console.logconsole.log('updating preferences')
    let selectedLanguage = lang.lang;
    let language;
    switch (selectedLanguage) {
      case 'English':
        language = 'en-gb';
        break;
      case 'German':
        language = 'de-de';
        break;
      case 'Czech':
        language = 'cs-cz';
        break;
      case 'Hungarian':
        language = 'hu';
        break;
      case 'Russian':
        language = 'ru';
        break;
      case 'French':
        language = 'fr-fr';
        break;
      case 'Romanian':
        language = 'ro';
        break;
      case 'Italian':
        language = 'it-it';
        break;
      case 'Spanish':
        language = 'es-es';
        break;
      case 'Portuguese':
        language = 'pt-pt';
        break;
      //Sweden
      case 'English ':
        language = 'sv-se';
        break;
      //India
      case 'English  ':
        language = 'en-in';
        break;
      case 'Chinese':
        language = 'zh-cn';
        break;
      case 'Polish':
        language = 'pl';
        break;
      case 'Indian':
        language = 'en-in';
        break;
      default:
        language = 'en-gb';
    }
    //Check for Latin America countries
    if (lang.country === 'Latin America' && language === 'es-es') {
      language = 'en-la';
    }

    let hasChangedLanguage = false;
    let supplierInfo;
    //find the langauge index for the more info button link
    let languageIndex;
    let moreInfoBtn;
    let currentData = this.state.cmsData.filter(version => {
      return version.data.region_name_in_english[0].text === lang.country;
    });

    currentData[0].data.available_languages_for_region.forEach(
      (language, index) => {
        if (language.text === lang.lang) {
          languageIndex = index;
        }
      }
    );
    moreInfoBtn =
      currentData[0].data.more_info_button_links[languageIndex].text;

    //console.log(this.state.queryParams)
    //if its the same country, but a different language
    if (
      (this.state.currentCountry === lang.country &&
        language !== this.state.language) ||
      this.state.queryParams
    ) {
      hasChangedLanguage = true;
      let supplierArray = this.state.cmsData.filter(version => {
        return version.data.region_name_in_english[0].text === lang.country;
      });

      supplierInfo = {
        supplier_address_line_1:
          supplierArray[0].data.supplier_address_line_1[0].text,
        supplier_address_line_2:
          supplierArray[0].data.supplier_address_line_2[0].text,
        supplier_address_line_3:
          supplierArray[0].data.supplier_address_line_3[0].text,
        supplier_email_address:
          supplierArray[0].data.supplier_email_address[0].text,
        supplier_name: supplierArray[0].data.supplier_name[0].text,
        supplier_postcode: supplierArray[0].data.supplier_postcode[0]
          ? supplierArray[0].data.supplier_postcode[0].text
          : '',
        supplier_phone_number:
          supplierArray[0].data.supplier_phone_number[0].text
      };
    }

    this.setState({
      currentCountry: lang.country,
      language: language,
      moreInfoLink: moreInfoBtn,
      currentLanguage: lang.lang,
      changedLanguage: hasChangedLanguage,
      supplierInfo: supplierInfo,
      userLanguageChange: true,
      noNativeLanguage:
        this.state.country === 'India' || this.state.country === 'Sweden'
          ? true
          : false
    });
  };

  updateRegion = region => {
    this.setState({
      country: region
    });
  };

  showModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  changeDefaultMode = () => {
    this.setState({ defaultMode: false });
  };

  /**
   *  Get value for translation,
   */
  getValueOf = function(scope, value) {
    // console.log('test',scope,value)
    function getValue(scope, str) {
      let obj = scope,
        arr;
      try {
        arr = str
          .split(/[\[\]\.]/) // split by [,],.
          .filter(el => el) // filter out empty one
          .map(el => el.replace(/^['"]+|['"]+$/g, '')); // remove string quotation
        arr.forEach(el => (obj = obj[el]));
      } catch (e) {
        obj = undefined;
      }

      return obj;
    }
    let result = getValue(scope, value);
    if (result === undefined) {
      return '';
    }
    return result;
  };

  render() {
    if (this.state.cmsData) {
      const documentVersions = this.state.cmsData;

      const EnglishVersionData = this.state.cmsData.filter(version => {
        return version.lang === 'en-gb';
      });

      return (
        <div className="app">
          {documentVersions.map((doc, index) => {
            doc.data.getValueOf = this.getValueOf;
            return doc.lang === this.state.language ? (
              <div key={index}>
                <Main
                  queryParams={this.state.queryParams}
                  noNativeLanguage={this.state.noNativeLanguage}
                  moreInfoLink={this.state.moreInfoLink}
                  changeDefaultMode={this.changeDefaultMode}
                  changedLanguage={this.state.changedLanguage}
                  supplierInfo={this.state.supplierInfo}
                  currentCountry={this.state.currentCountry}
                  currentLanguage={this.state.currentLanguage}
                  englishVersionData={EnglishVersionData[0]}
                  defaultMode={this.state.defaultMode}
                  updateRegion={this.updateRegion}
                  fullState={documentVersions}
                  modalOpen={this.state.modalOpen}
                  closeModal={this.closeModal}
                  updatePreferences={this.updatePreferences}
                  pageData={doc}
                  country={this.state.country}
                  showModal={this.showModal}
                />
              </div>
            ) : null;
          })}
        </div>
      );
    }
    return <h1> </h1>;
  }
}
export default App;
