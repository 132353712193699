import React, { Component } from "react";

class TechnicalTable extends Component {
  componentDidMount() {}
  render() {
    const scope = this.props.pageData;

    return (
      <div className="technical-table">
        <table style={{ height: "467px" }}>
          <tbody>
            <tr style={{ height: "39px" }}>
              <td style={{ height: "39px" }}>
                {scope.getValueOf(scope, "tt_1_row[0].text")}
              </td>
              <td style={{ height: "39px", textAlign: "center" }}>
                <p style={{ margin: "0px" }}>
                  <strong>
                    O<sub>2</sub>,&nbsp;
                  </strong>
                  <strong>
                    N<sub>2</sub>,<br />H
                  </strong>
                  <strong>
                    <sub>2</sub>
                  </strong>
                  <strong>, He</strong>
                </p>
              </td>
              <td style={{ height: "39px", textAlign: "center" }}>
                <strong>
                  Ar
                  <br />
                  Ar/CO
                  <sub>
                    2<br />
                  </sub>
                  F.G.
                </strong>
              </td>
              <td
                style={{ height: "39px", width: "90px", textAlign: "center" }}
              >
                <strong>
                  CO
                  <sub>
                    2<br />
                  </sub>
                </strong>
              </td>
              <td
                style={{ width: "75px", height: "39px", textAlign: "center" }}
              >
                <p style={{ margin: "0px" }}>
                  <strong>
                    {scope.getValueOf(scope, "tt_1_row_values[0].text")}
                  </strong>
                </p>
              </td>
              <td
                style={{ width: "75px", height: "39px", textAlign: "center" }}
              >
                <p style={{ margin: "0px" }}>
                  <strong>
                    {scope.getValueOf(scope, "tt_1_row_values[1].text")}
                  </strong>
                </p>
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_2_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_2_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_3_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_3_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "26px" }}>
              <td style={{ height: "26px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_4_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "26px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_4_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_5_row[0].text")}
              </td>
              <td
                style={{ width: "256px", height: "13px", textAlign: "center" }}
                colSpan={4}
              >
                {scope.getValueOf(scope, "tt_5_row_values[0].text")}
              </td>
              <td
                style={{ width: "62px", height: "13px", textAlign: "center" }}
                colSpan={2}
              >
                {scope.getValueOf(scope, "tt_5_row_values[1].text")}
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_6_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={3}
              >
                {scope.getValueOf(scope, "tt_6_row_values[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={2}
              >
                {scope.getValueOf(scope, "tt_6_row_values[1].text")}
              </td>
            </tr>
            <tr style={{ height: "26px" }}>
              <td style={{ height: "26px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_7_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "26px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_7_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "39px" }}>
              <td style={{ height: "39px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_8_row[0].text")}
              </td>
              <td style={{ height: "39px", textAlign: "center" }} colSpan={2}>
                {scope.getValueOf(scope, "tt_8_row_values[0].text")}
              </td>
              <td
                style={{ height: "39px", width: "90px", textAlign: "center" }}
              >
                {scope.getValueOf(scope, "tt_8_row_values[1].text")}{" "}
              </td>
              <td
                style={{ width: "75px", height: "39px", textAlign: "center" }}
                colSpan={2}
              >
                {scope.getValueOf(scope, "tt_8_row_values[2].text")}
              </td>
            </tr>
            <tr style={{ height: "39px" }}>
              <td style={{ height: "39px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_9_row[0].text")}
              </td>
              <td
                style={{ height: "39px", width: "80px", textAlign: "center" }}
              >
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[0].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[1].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[2].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[3].text")}
                </p>
              </td>
              <td style={{ height: "39px", textAlign: "center" }} colSpan={2}>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[4].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[5].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[6].text")}
                </p>
                <p style={{ margin: "0px" }}>
                  {scope.getValueOf(scope, "tt_9_row_values[7].text")}
                </p>
              </td>
              <td
                style={{ width: "75px", height: "39px", textAlign: "center" }}
              >
                {scope.getValueOf(scope, "tt_9_row_values[8].text")}
              </td>
              <td
                style={{ width: "75px", height: "39px", textAlign: "center" }}
              >
                {scope.getValueOf(scope, "tt_9_row_values[9].text")}
              </td>
            </tr>
            <tr style={{ height: "26px" }}>
              <td style={{ height: "26px", width: "100px" }}>
                {scope.getValueOf(scope, "tt_10_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "26px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_10_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "150px" }}>
                {scope.getValueOf(scope, "tt_11_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_11_row_values[0].text")}
              </td>
            </tr>
            <tr style={{ height: "13px" }}>
              <td style={{ height: "13px", width: "150px" }}>
                {scope.getValueOf(scope, "tt_12_row[0].text")}
              </td>
              <td
                style={{ width: "75px", height: "13px", textAlign: "center" }}
                colSpan={5}
              >
                {scope.getValueOf(scope, "tt_12_row_values[0].text")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default TechnicalTable;
