import React, { Component } from "react";

class ModalComponent extends Component {
  state = {
    region: "",
    language: "",
    availableLanguages: ""
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = {
      lang: document.getElementById("language").value,
      country: document.getElementById("region").value
    };
    this.props.updatePreferences(data);
    this.props.closeModal();
    this.props.changeDefaultMode();
  };

  handleLanguageChange = e => {
    this.setState(
      {
        language: e.target.value,
        availableLanguages: this.findAvailableLanguages()
      }
      //   () => {console.log(this.state.language)}
    );
  };

  handleRegionChange = e => {
    this.setState({
      region: e.target.value
    });
    this.props.updateRegion(e.target.value);
  };

  findAvailableLanguages = () => {
    let pageLanguage;

    this.props.fullState.forEach(pageVersion => {
      if (
        pageVersion.data.region_name_in_english[0].text === this.props.country
      ) {
        pageLanguage = pageVersion;
      }
    });
    // this should fall back to English if there is no translatable country in the CMS
    if (pageLanguage == null) {
      pageLanguage = this.props.fullState.filter(el => {
        return el.uid === "uk-version";
      })[0];
    }
    return pageLanguage;
  };

  render() {
    const pageData = this.props.pageData.data;
    const languagesAvailable = this.findAvailableLanguages();
    const fullState = this.props.fullState;
    return (
      <div className="modal-container">
        <div className="modal-div">
          <p className="modal-text">{pageData.modal_text[0].text}</p>
          <p className="modal-text uppercase">
            {pageData.select_region_text[0].text}
          </p>
          <form className="modal-form" onSubmit={e => this.handleSubmit(e)}>
            <select
              id="region"
              defaultValue={this.props.country}
              onChange={e => this.handleRegionChange(e)}
              className="modal-select text-medium"
            >
              {fullState.map((pageVersion, index) => (
                <option
                  key={index}
                  value={pageVersion.data.region_name_in_english[0].text}
                >
                  {pageVersion.data.region_name_in_english[0].text}
                </option>
              ))}
            </select>

            <select
              id="language"
              defaultValue={this.props.currentLanguage}
              onChange={e => this.handleLanguageChange(e)}
              className="modal-select  text-medium"
            >
              {languagesAvailable.data.available_languages_for_region.map(
                (language, index) => (
                  <option key={index} value={language.text}>
                    {" "}
                    {language.text}
                  </option>
                )
              )}
            </select>

            <input
              className="btn-red text-medium margin-button"
              type="submit"
              value={pageData.modal_button_text[0].text}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default ModalComponent;
