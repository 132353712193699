import React, { Component } from "react";
import supplierIcon from "../../assets/Supplier_icon.svg";
import pinIcon from "../../assets/location_pin_icon.svg";
import phoneIcon from "../../assets/phone_icon.svg";
import emailIcon from "../../assets/email_icon.svg";

export default class SupplierInfo extends Component {
  shouldComponentUpdate(nextProps, nextState) {
   // if(this.props.queryParams){
      return true;
    //}
 
  }
  render() {
    return (
      <div className="supplier-info text-medium">
        <img alt="supplier" src={supplierIcon} className="supplier-icon" />
        <span className="supplier-name uppercase white">
          {this.props.defaultMode
            ? this.props.notAvailableData.company_name[0].text
            : this.props.changedLanguage
            ? this.props.pageData.getValueOf(
                this.props.supplierInfo,
                "supplier_name"
              )
            : this.props.pageData.getValueOf(
                this.props.pageData,
                "supplier_name[0].text"
              )}
        </span>
        <div className="address">
          <img alt="pin" src={pinIcon} className="pin-icon" />
          {this.props.defaultMode ? (
            <div className="address-list">
              {this.props.notAvailableData.company_address.map(
                (line, index) => (
                  <p key={index} className="white uppercase">
                    {line.text}
                  </p>
                )
              )}
            </div>
          ) : (
            <div className="address-list">
              <p className="line_one white uppercase">
                {this.props.changedLanguage
                  ? this.props.pageData.getValueOf(
                      this.props.supplierInfo,
                      "supplier_address_line_1"
                    )
                  : this.props.pageData.getValueOf(
                      this.props.pageData,
                      "supplier_address_line_1[0].text"
                    )}
              </p>
              <p className="line_two white uppercase">
                {this.props.changedLanguage
                  ? this.props.pageData.getValueOf(
                      this.props.supplierInfo,
                      "supplier_address_line_2"
                    )
                  : this.props.pageData.getValueOf(
                      this.props.pageData,
                      "supplier_address_line_2[0].text"
                    )}
              </p>
              <p className="line_three white uppercase">
                {this.props.changedLanguage
                  ? this.props.pageData.getValueOf(
                      this.props.supplierInfo,
                      "supplier_address_line_3"
                    )
                  : this.props.pageData.getValueOf(
                      this.props.pageData,
                      "supplier_address_line_3[0].text"
                    )}
              </p>
              <p className="postcode white uppercase">
                {this.props.changedLanguage
                  ? this.props.pageData.getValueOf(
                      this.props.supplierInfo,
                      "supplier_postcode"
                    )
                  : this.props.pageData.getValueOf(
                      this.props.pageData,
                      "supplier_postcode[0].text"
                    )}
              </p>
            </div>
          )}
        </div>
        {this.props.defaultMode ? (
          <div className="email-container">
            <img alt="email" src={emailIcon} className="email-icon" />
            <a
              href={`mailto:${
                this.props.notAvailableData.company_email[0].text
              }`}
              className="uppercase white contact-links"
            >
              {this.props.notAvailableData.company_email[0].text}}
            </a>
          </div>
        ) : (
          <div className="email-container">
            <img alt="email" src={emailIcon} className="email-icon" />
            <a
              href={`mailto:${
                this.props.changedLanguage
                  ? this.props.pageData.getValueOf(
                      this.props.supplierInfo,
                      "supplier_email_address"
                    )
                  : this.props.pageData.getValueOf(
                      this.props.pageData,
                      "supplier_email_address[0].text"
                    )
              }`}
              className="uppercase white contact-links"
            >
              {this.props.changedLanguage
                ? this.props.pageData.getValueOf(
                    this.props.supplierInfo,
                    "supplier_email_address"
                  )
                : this.props.pageData.getValueOf(
                    this.props.pageData,
                    "supplier_email_address[0].text"
                  )}
            </a>
          </div>
        )}
        {<img alt="phone" src={phoneIcon} className="phone-icon" />}
        {this.props.defaultMode ? (
          <a
            href={`tel:${
              this.props.notAvailableData.company_telephone[0].text
            }`}
            className="supplier-phone uppercase white contact-links"
          >
            {this.props.notAvailableData.company_telephone[0].text}
          </a>
        ) : (
          <a
            href={`tel:${
              this.props.changedLanguage
                ? this.props.pageData.getValueOf(
                    this.props.supplierInfo,
                    "supplier_phone_number"
                  )
                : this.props.pageData.getValueOf(
                    this.props.pageData,
                    "supplier_phone_number[0].text"
                  )
            }`}
            className="supplier-phone uppercase white contact-links"
          >
            {this.props.changedLanguage
              ? this.props.pageData.getValueOf(
                  this.props.supplierInfo,
                  "supplier_phone_number"
                )
              : this.props.pageData.getValueOf(
                  this.props.pageData,
                  "supplier_phone_number[0].text"
                )}
          </a>
        )}
      </div>
    );
  }
}
