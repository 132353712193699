import React from "react";
import { Tabs, Tab, Carousel } from "react-bootstrap";
import slideImgOne from "../../assets/Procontrol_1-min.jpg";
import slideImgTwo from "../../assets/Procontrol_2.jpg";
import slideImgThree from "../../assets/Procontrol_3-min.jpg";
import leftArrow from "../../assets/arrow-left.svg";
import rightArrow from "../../assets/arrow-right.svg";
import TechnicalTable from "./TechnicalTable";

export default function ProductInformation(props) {
  const pageData = props.pageData;
  return (
    <div className="product-info text-small">
      <div className="product-info-container">
        <Tabs defaultActiveKey="product">
          <Tab
            eventKey="product"
            title={pageData
              .getValueOf(pageData, "product_information_title[0].text")
              .toUpperCase()}
            className="product-info-tab"
          >
            <p className="white uppercase info-title">
              {pageData.getValueOf(pageData, "part_one_title[0].text")}
            </p>
            <ul className="features-list white small">
              {pageData.part_one_text.map((feature, index) => (
                <li key={index}>{feature.text}</li>
              ))}
            </ul>

            <p className="white uppercase info-title">
              {pageData.getValueOf(pageData, "part_two_title[0].text")}
            </p>
            <ul className="advantages-list white small ">
              {pageData.part_two_text.map((feature, index) => (
                <li key={index}>{feature.text}</li>
              ))}
            </ul>
            <br />
            <div className="more-info-container">
              <a
                href={
                  props.moreInfoLink
                    ? props.moreInfoLink
                    : pageData.getValueOf(
                        pageData,
                        "more_info_button_links[0].text"
                      )
                }
                className="btn-grey text-medium uppercase more-info-btn text-bold"
              >
                {pageData.getValueOf(
                  pageData,
                  "more_information_button_text[0].text"
                )}
              </a>
            </div>
          </Tab>
          <Tab
            eventKey="technical"
            title={pageData
              .getValueOf(pageData, "technical_information_title[0].text")
              .toUpperCase()}
            className="technical-info-tab"
          >
            <TechnicalTable pageData={pageData} />
          </Tab>
        </Tabs>
      </div>
      <div className="product-carousel">
        <Carousel
          indicators={false}
          nextIcon={
            <img className="slider-btn" alt="right-arrow" src={rightArrow} />
          }
          prevIcon={
            <img className="slider-btn" alt="left-arrow" src={leftArrow} />
          }
          autoPlay={false}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slideImgOne}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slideImgTwo}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slideImgThree}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

