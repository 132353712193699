import React from "react";
import GceLogo from "../../assets/gce-gascontrol.svg";
import Toast from "../Toast/Toast";
import VideoPlayer from 'react-video-js-player';


function Hero(props) {
  
  return (
    <div className="hero">
      <div className="hero-container">
        <div className="header-container">
          <img alt="gce-logo" className="gce-logo" src={GceLogo} />
          <Toast       
            noNativeLanguage={props.noNativeLanguage}
            currentLanguage={props.currentLanguage}
            updatePreferences={props.updatePreferences}
            defaultMode={props.defaultMode}
            fullState={props.fullState}
            currentCountry={props.currentCountry}
            pageData={props.pageData}
            showModal={props.showModal}
            country={props.country}
          />
        </div>
       {props.currentCountry === 'China'
        ? (<VideoPlayer       
          controls={true}
          className="procontrol-video"         
          src="http://d2cag6x8o5msw0.cloudfront.net/procontrol.mp4" />        
        )
        : (
          <iframe
          title="procontrol"
          className="procontrol-video"
          src={`${
            props.pageData.yt_video_url[0]
              ? props.pageData.yt_video_url[0].text
              : "https://www.youtube.com/embed/zwch9IzeDik"
          }?autoplay=1&mute=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        )
      } 
        
      </div>
    </div>
  );
}

export default Hero;
