import React, { Component } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import SupplierInfo from "./SupplierInfo";



class Supplier extends Component {
  state = {
    name: " ",
    email: " ",
    message: " ",
    optin: false,
    enquiryType: "sales",
    recaptcha: " ",
    error: false,
    successMessage: false,
    errorMessage: " "
  };

  toggleEnquiryType = (event, enType) => {
    this.state.enquiryType === "sales"
      ? this.setState({ enquiryType: "general" })
      : this.setState({ enquiryType: "sales" });
    //reset state
    this.setState({
      name: " ",
      email: " ",
      message: " ",
      optin: false,
      recaptcha: " ",
      error: false,
      successMessage: false,
      errorMessage: " "
    });
    this.captcha.reset();
  };

  changeValue = (e, field) => {
    const newValue = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      [field]: newValue
    }));
  };

  checkInputs = callback => {
    //inputs validation
    if (this.state.name === "" || this.state.name === " ") {
      this.setState({
        error: true,
        errorMessage: this.props.pageData.name_validation_error_message[0].text
      });
    } else if (this.state.email === "" || this.state.email === " ") {
      this.setState({
        error: true,
        errorMessage: this.props.pageData.email_validation_error_message[0].text
      });
    } else if (this.state.recaptcha === "" || this.state.recaptcha === " ") {
      this.setState({
        error: true,
        errorMessage: this.props.pageData.recaptcha_validation_error_message[0]
          .text
      });
    } else if (this.state.optin === false) {
      this.setState({
        error: true,
        errorMessage: this.props.pageData.checkbox_validation_error_message[0]
          .text
      });
    } else {
      this.setState({ error: false, errorMessage: "" }, callback());
    }
  };
  postData = () => {
    const country =
      this.state.enquiryType === "sales" ? this.props.country : null;
    const data = {
      name: this.state.name,
      customerEmail: this.state.email,
      countryName: country,
      message: this.state.message,
      optin: this.state.optin,
      "g-recaptcha-response": this.state.recaptcha
    };

    fetch("https://api.gcegroup.com/index.php", {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status === 201 || res.status === 200) {
        //console.group("posted successfully", res);
        this.setState({
          successMessage: true,
          name: " ",
          email: " ",
          message: " ",
          optin: false,
          enquiryType: "sales",
          recaptcha: " ",
          error: false,
          errorMessage: ""
        });
        this.captcha.reset();
      } else {
        this.setState({
          errorMessage: "SOMETHING WENT WRONG, PLEASE TRY AGAIN"
        });
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.checkInputs(this.postData);
  };

  onChangeRecaptcha = value => {
    this.setState({ recaptcha: value });
  };

  render() {

    //use recaptcha.net for China
    window.recaptchaOptions = {
      useRecaptchaNet: this.props.country === 'China' ? true : false,
      };
    
    const pageData = this.props.pageData;
    const supplierTitle = pageData
      .getValueOf(pageData, "supplier_title[0].text")
      .split(" ")
      .splice(0, 3);
    const supplierRemainingTitle = pageData
      .getValueOf(pageData, "supplier_title[0].text")
      .split(" ")
      .splice(3)
      .join(" ");
    const formTitle = pageData
      .getValueOf(pageData, "form_title[0].text")
      .split(" ")
      .splice(0, 3);
    const formRemainingTitle = pageData
      .getValueOf(pageData, "form_title[0].text")
      .split(" ")
      .splice(3)
      .join(" ");

    const btnOneClass =
      this.state.enquiryType === "sales" ? "btn-red" : "btn-grey";
    const btnTwoClass =
      this.state.enquiryType === "sales" ? "btn-grey" : "btn-red";

    const notAvailableData = this.props.englishVersionData.data.body[0].primary;
    const defaultMode = this.props.defaultMode;

    return (
      <div className="supllier-contact">
        <div className="supplier">
          <div className="supplier-wrapper">
            <p className="white supplier-title text-large uppercase">
              {defaultMode ? (
                <strong>{notAvailableData.section_title[0].text}</strong>
              ) : (
                <span>
                  <strong>{supplierTitle[0]} </strong>
                  {supplierTitle[1]} <strong>{supplierTitle[2]}</strong>
                  {supplierRemainingTitle}
                </span>
              )}
            </p>
            <p className="white text-medium uppercase text-bold not-launched-text">
              {defaultMode
                ? notAvailableData.not_available_message[0].text
                : null}
            </p>
            <SupplierInfo
              queryParams={this.props.queryParams}
              supplierInfo={this.props.supplierInfo}
              changedLanguage={this.props.changedLanguage}
              pageData={pageData}
              notAvailableData={notAvailableData}
              defaultMode={defaultMode}            
            />
          </div>
        </div>

        <div className="contact-form">
          <p className="red form-title text-large uppercase">
            {`${formTitle[0]} `}
            <strong>
              {formTitle[1]} {formTitle[2]}
            </strong>
            {formRemainingTitle}
          </p>
          {defaultMode ? (
            <button
              onClick={e => this.toggleEnquiryType(e)}
              className={`${btnOneClass} text-medium form-btn sales-btn uppercase`}
            >
              {pageData.getValueOf(pageData, "form_type_two[0].text")}
            </button>
          ) : (
            <div className="formNavContainer">
              <button
                onClick={e => this.toggleEnquiryType(e)}
                className={`${btnOneClass} text-medium form-btn sales-btn uppercase`}
              >
                {pageData.getValueOf(pageData, "form_type_one[0].text")}
              </button>
              <button
                onClick={this.toggleEnquiryType}
                className={`${btnTwoClass} text-medium form-btn sales-btn uppercase`}
              >
                {pageData.getValueOf(pageData, "form_type_two[0].text")}
              </button>
            </div>
          )}

          <form method="POST" onSubmit={this.handleSubmit}>
            <label className="text-medium" htmlFor="name">
              {pageData.getValueOf(pageData, "name_field[0].text")}*
            </label>
            <input
              onChange={e => this.changeValue(e, "name")}
              required
              className="name-field field text-medium"
              type="text"
              value={this.state.name}
              name="name"
            />

            <label className="text-medium" htmlFor="customerEmail">
              {pageData.getValueOf(pageData, "email_field[0].text")}*
            </label>
            <input
              onChange={e => this.changeValue(e, "email")}
              required
              className="email-field field text-medium"
              type="email"
              name="customerEmail"
              value={this.state.email}
            />

            <label className="text-medium" htmlFor="message">
              {pageData.getValueOf(pageData, "message_field[0].text")}
            </label>
            <textarea
              onChange={e => this.changeValue(e, "message")}
              className="message-field field text-medium"
              name="message"
              value={this.state.message}
            />

            <div className="privacy-container">
              <input
                onChange={() =>
                  this.setState(prevState => ({ optin: !prevState.optin }))
                }
                type="checkbox"
                name="optin"
                checked={this.state.optin}
                required
                className="privacy-checkbox"
              />
              <label htmlFor="optin" className="text-small ">
                {pageData.getValueOf(pageData, "privacy_policy_text[0].text")}
              </label>
            </div>
            <div className="bottom-form">
              <ReCAPTCHA
                required
                className="g-recaptcha"
                ref={r => (this.captcha = r)}
                sitekey="6LeXn6oUAAAAAEvZpXXqrYBg5v_anH58YIyt3y1Q"
                onChange={this.onChangeRecaptcha}
              />
              {this.state.successMessage ? (
                <p className="red uppercase text-bold success-msg text-medium">
                  {pageData.successful_submission_message[0].text}
                </p>
              ) : (
                <input
                  type="submit"
                  value={pageData.getValueOf(
                    pageData,
                    "submit_button_text[0].text"
                  )}
                  className="uppercase red submit-btn"
                />
              )}
              {
                <p className="red text-small error-msg ">
                  {this.state.errorMessage}
                </p>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Supplier;
