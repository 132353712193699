import React, { Component } from "react";
import globe from "../../assets/White_Globe.png";

class Toast extends Component {
  findAvailableLanguages = () => {
    let pageLanguage;
    this.props.fullState.forEach(pageVersion => {
      if (
        pageVersion.data.region_name_in_english[0].text === this.props.country
      ) {
        pageLanguage = pageVersion;
      }
    });
    // this should fall back to English if there is no translatable country in the CMS
    if (pageLanguage == null) {
        pageLanguage = this.props.fullState.filter(el => {
        return el.uid === "uk-version";
      })[0];
    }
    return pageLanguage;
  };
  handleLanguageChange = () => {
    let data = {
      lang: document.getElementById("toast-language-select").value,
      country: this.props.country
    };
    this.props.updatePreferences(data);
  };
  render() {       
    var languagesAvailable = this.findAvailableLanguages();
    return (
      <div className="notification">
        <img src={globe} alt="gce-globe" className="globe" />
        <span className=" uppercase white text-small ">
          { this.props.country === '' ? 'WAITING FOR BROWSER LOCATION' : this.props.pageData.getValueOf(this.props.pageData,"location_detection_text[0].text")}
        </span>
        <br />
        <p id="regionTitle" className="red uppercase text-small center">
          {this.props.country}
        </p>
        {!this.props.defaultMode
         ? (
          <div className="toast-select-container">
            <p className="white uppercase text-small center is-inlineblock">
              LANGUAGE
            </p>
            <select
              id="toast-language-select"
              value={this.props.currentLanguage}
              onChange={e => this.handleLanguageChange(e)}
              className="modal-select toast-language-select is-inlineblock  text-small"
            >
              {languagesAvailable.data.available_languages_for_region.map(
                (language, index) => (
                  <option key={index} value={language.text}>
                    {" "}
                    {language.text}
                  </option>
                )
              )}
            </select>
          </div>
         ) : null}
        <p className="white uppercase text-small center is-inlineblock">
          { this.props.country === '' ? null : "Not your region?" } &nbsp;
        </p>
        <p
          onClick={() => this.props.showModal()}
          className="region-btn uppercase red text-small is-inlineblock"
        >
         { this.props.country === '' ? 'MANUALLY SELECT YOUR REGION' : "CHANGE REGION" } 
        </p>
      </div>
    );
  }
}

export default Toast;
